import moment from 'moment-timezone';


export const dateformat = function (value) {
    if (value) {
        return moment(value).format('DD.MM.YYYY')
    } else {
        return ""
    }
}
export const dateFormatWithTime = function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY H:m:s')
    } else {
        return ""
    }
}
export const formatCurrency = function (value) {
    if (value) {
        return value.toLocaleString('cs-CS') + ' Kč';
    }
    return "nezadáno";
}


export default dateformat
